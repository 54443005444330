import React from "react";
import { useNavigate } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "./Dashboard.css";

const Dashboard = () => {
    const navigate = useNavigate();

    const handleAtlasViewClick = () => {
        const storedAuth = localStorage.getItem("_auth");
        const url = storedAuth
            ? process.env.REACT_APP_ATLAS_VIEW_URL + `/?q=${storedAuth}`
            : "https://atlas.fornaxenergytech.com";
        window.open(url, "_blank");
    };

    const handleFornaxViewClick = () => {
        // const storedAuth = localStorage.getItem("_auth");
        // const url = storedAuth ? process.env.REACT_APP_MAP_VIEW_URL + `/?q=${storedAuth}` : 'https://map.fornaxenergytech.com/';
        // window.open(url, '_blank');
        navigate("/map");
    };

    const handleReportViewClick = () => {
        const storedAuth = localStorage.getItem("_auth");
        const url = storedAuth
            ? process.env.REACT_APP_REPORT_VIEW_URL + `/?q=${storedAuth}`
            : "https://reports.fornaxenergytech.com/";
        window.open(url, "_blank");
    };

    const handleDataViewClick = () => {
        const storedAuth = localStorage.getItem("_auth");
        const url = storedAuth
            ? process.env.REACT_APP_DATA_VIEW_URL + `/?q=${storedAuth}`
            : "https://data-nexus.fornaxet.com/";
        window.open(url, "_blank");
    };

    const handleLogout = () => {
        localStorage.removeItem("_auth");
        localStorage.removeItem("user");
        navigate("/");
    };

    function isValidJSON(jsonString) {
        try {
          JSON.parse(jsonString);
          return true; // If parsing succeeds, it's valid JSON
        } catch (error) {
          return false; // If parsing fails, it's not valid JSON
        }
    }

    const userEmail = isValidJSON(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : "Not logged in";

    return (
        <div className="dashboard-wrapper">
            {/* <div className="user-info"> */}
            <nav className="top-navbar">
                <div className="ms-auto">
                    <span style={{ padding: "5px 10px", fontSize: "15px" }}>
                        {userEmail.first_name+' '+userEmail.last_name ?? 'user'}
                    </span>
                    <button className="logout-button" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </nav>
            <div className="dashboard-content">
                <div className="container">
                    <h3 className="text-center py-5">Welcome to IntelliGIS</h3>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                            <div className="row g-4">
                                <div className="col-6 col-md-6">
                                    <div className="box-item">
                                        <div
                                            className="box fornax-view"
                                            onClick={handleFornaxViewClick}
                                        >
                                            <img
                                                src="Grid-View-thumb.jpg"
                                                className="img-fluid w-100"
                                                alt=""
                                            />
                                            <h4 className="text-white">
                                                Grid View
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="box-item">
                                        <div
                                            className="box atlas-view"
                                            onClick={handleAtlasViewClick}
                                        >
                                            <img
                                                src="Atlas-View-thumb.jpg"
                                                className="img-fluid w-100"
                                                alt=""
                                            />
                                            <h4 className="text-white">
                                                Atlas View
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="box-item">
                                        <div
                                            className="box"
                                            onClick={handleReportViewClick}
                                        >
                                            <img
                                                src="Reports-thumb.jpg"
                                                className="img-fluid w-100"
                                                alt=""
                                            />
                                            <h4 className="text-white">
                                                Reports
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="box-item">
                                        <div
                                            className="box"
                                            onClick={handleDataViewClick}
                                        >
                                            <img
                                                src="Contact-thumb.jpg"
                                                className="img-fluid w-100"
                                                alt=""
                                            />
                                            <h4 className="text-white">
                                                Data Nexus
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
