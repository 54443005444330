import React, { useState, useEffect } from 'react';
import L from "leaflet";
import { MapContainer, LayersControl, TileLayer, GeoJSON, ZoomControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import leaflet CSS
import { BingLayer } from "react-leaflet-bing-v2";
import { Modal, Button } from 'react-bootstrap'; // Import Modal from react-bootstrap

const pointStyle = {
    color: '#3388ff',
    weight: 5
};

const lineStyle = {
    color: '#ffdb58',
    weight: 3,
    opacity: 3
};

const style = (feature) => {
    switch (feature.geometry.type) {
        case 'LineString':
            return lineStyle;
        case 'Point':
            return pointStyle;
        default:
            return { color: '#000', weight: 1 }; // Default style
    }
};

// Function to convert feature point to marker
const pointToLayer = (feature, latlng) => {
    const dataName = feature.properties?.point_type || "HT Pole";

    // Default icon URL for HT pole
    let imgURL = "map_icons/ht_pole.svg";
    switch (dataName) {
        case "Existing Pole(Double Circuit)":
            imgURL = "map_icons/existing_pole.svg";
            break;
        case "Fuse":
            imgURL = "map_icons/Fuse.svg";
            break;
        case "CTPT":
            imgURL = "map_icons/CTPT.svg";
            break;
        case "HT Pole":
            imgURL = "map_icons/ht_pole.svg";
            break;
        case "Switch":
            imgURL = "map_icons/Switch.svg";
            break;
        case "Gentry":
            imgURL = "map_icons/Gentry.svg";
            break;
        case "Transformer":
            imgURL = "map_icons/Transformer.svg";
            break;
        case "HT Route Point":
            imgURL = "map_icons/ht_route_point.svg";
            break;
        case "RMU":
            imgURL = "map_icons/RMU.svg";
            break;
        case "RMU with TC":
            imgURL = "map_icons/rmu_with_tc.svg";
            break;
        default:
            imgURL = "map_icons/default_icon.svg"; // Fallback icon if no match
            break;
    }

    // Set icon size based on type
    const iconSize = ["HT Pole", "Existing Pole(Double Circuit)", "HT Route Point"].includes(dataName)
        ? [10, 10]
        : [20, 20];

    // Create the Leaflet icon
    const icon = L.icon({
        iconUrl: imgURL,
        iconSize: iconSize
    });

    // Create a marker with the icon
    return L.marker(latlng, { icon });
};

const onEachFeature = (feature, layer, handleFeatureClick) => {
    layer.on({
        click: () => {
            handleFeatureClick(feature.properties);
        }
    });
};

// Function to get parent coordinates
const getParentCoordinates = (parentId, features) => {
    const parentFeature = features.find(
        (feature) => feature.properties.id === parentId
    );
    return parentFeature ? parentFeature.geometry.coordinates : null;
};

const convertToGeoJSON = (locations) => {
    // const features = locations.map(location => ({
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [location.geometry.coordinates[1], location.geometry.coordinates[0]]
    //     },
    //     properties: {
    //         ...location.properties,
    //         point_type: location.properties.point_type,
    //         project_name: location.project_id,
    //         line_props: location.properties.line_props
    //     }
    // }));

    // const lines = {
    //     type: "Feature",
    //     geometry: {
    //         type: "LineString",
    //         coordinates: features.map(feature => feature.geometry.coordinates)
    //     }
    // };

    // return {
    //     type: "FeatureCollection",
    //     features: [...features, lines]
    // };

    const features = locations.flatMap(location => {
        // Create the base feature (Point)
        const pointFeature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [location.geometry.coordinates[1], location.geometry.coordinates[0]],
          },
          properties: {
            ...location.properties,
            point_type: location.properties.point_type,
            project_name: location.project_id,
            line_props: location.properties.line_props,
          },
        };
      
        // Check for parent feature to create LineString
        const parentLocation = locations.find(parent => parent.id === location.properties.parent_id);
      
        const lineFeature = parentLocation
          ? {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  [location.geometry.coordinates[1], location.geometry.coordinates[0]], // Current feature
                  [parentLocation.geometry.coordinates[1], parentLocation.geometry.coordinates[0]], // Parent feature
                ],
              },
              properties: {
                current_id: location.id,
                parent_id: parentLocation.id,
                line_props: location.properties.line_props, // Optional: Pass additional line properties
              },
            }
          : null;
      
        // Return both Point and LineString features (if LineString exists)
        return lineFeature ? [pointFeature, lineFeature] : [pointFeature];
    });

    return {
        type: "FeatureCollection",
        features: [...features]
    };
    
};

const FitBoundsToGeoJSON = ({ geojsonData }) => {
    const map = useMap();
  
    useEffect(() => {
      if (geojsonData) {
        const layer = L.geoJSON(geojsonData);
        map.fitBounds(layer.getBounds());  // Adjust map view to the bounds of the GeoJSON
      }
    }, [geojsonData, map]);
  
    return null;
};

const ViewTaskMap = ({ taskLocations }) => {
    const { BaseLayer } = LayersControl;
    const [activeLayer, setActiveLayer] = useState("OpenStreetMap");
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [pointProps, setPointProps] = useState(null);
    const [lineProps, setLineProps] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    
    const handleFeatureClick = (properties) => {
        setSelectedFeature(properties);
        setLineProps(JSON.parse(properties.line_props));
        setPointProps(JSON.parse(properties.point_props));
        setSelectedTaskId(properties.id);
        setShowModal(true);
    };
    const geojsonData = convertToGeoJSON(taskLocations);

    const openFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    const formatName = (str) => {
        return str
          .split('_') // Split by underscore
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
          .join(' '); // Join with a space
    };

    return (
        <>
            <MapContainer
                zoomControl={false}
                zoom={15}
                style={{ height: '100vh', width: '100%' }}
            >
                <LayersControl>
                    <ZoomControl position="bottomright" />
                    <BaseLayer
                        checked={activeLayer === "empty"}
                        name="Canvas"
                    >
                        <TileLayer
                            url="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                            maxZoom={30}
                            attribution=""
                        />
                    </BaseLayer>

                    <BaseLayer
                        checked={activeLayer === "OpenStreetMap"}
                        name="OpenStreetMap"
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            maxZoom={30}
                            attribution=""
                        />
                    </BaseLayer>

                    <BaseLayer
                        checked={activeLayer === "Satellite"}
                        name="Satellite"
                    >
                        <TileLayer
                            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                            maxZoom={20}
                            subdomains={["mt1", "mt2", "mt3"]}
                        />
                    </BaseLayer>

                    <BaseLayer
                        checked={activeLayer === "BingMaps"}
                        name="Bing Maps"
                    >
                        <BingLayer
                            bingkey="AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L"
                            type="AerialWithLabels"
                            maxNativeZoom={19}
                        />
                    </BaseLayer>
                </LayersControl>

                <GeoJSON
                    data={geojsonData}
                    //pointToLayer={pointToLayer}  // Convert points to markers with icons
                    onEachFeature={(feature, layer) => onEachFeature(feature, layer, handleFeatureClick)}
                    style={style}
                />
                 <FitBoundsToGeoJSON geojsonData={geojsonData} />
            </MapContainer>

            {selectedFeature && (
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Network Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className='table table-custom table-bordered table-striped table-hover'>
                            <tr>
                                <th>Temp ID:</th>
                                <td>{selectedFeature.id}</td>
                            </tr>
                            <tr>
                                <th>Parent ID:</th>
                                <td>{selectedFeature.parent_id}</td>
                            </tr>
                            <tr>
                                <th>Point Type:</th>
                                <td>{selectedFeature.point_type}</td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Point Properties</th>                                
                            </tr>
                            {Object.entries(pointProps || {}).map(([key, value]) => (
                                <tr key={key}>
                                    <th>{formatName(key)}:</th>
                                    <td>{(key === "imgUrl" || key === "fileUrl") ? <button onClick={(value) => openFile(value)} className='btn-custom'>View File</button> :  value}</td>
                                </tr>
                            ))}
                            <tr>
                                <th colSpan={2}>Line Properties</th>
                            </tr>
                            {Object.entries(lineProps || {}).map(([key, value]) => (
                                <tr key={key}>
                                    <th>{formatName(key)}:</th>
                                    <td>{value}</td>
                                </tr>
                            ))}
                            <tr>
                                <th>Data Type:</th>
                                <td>{selectedFeature.data_type || 'HT Network'}</td>
                            </tr>
                        </table>                        
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer> */}
                </Modal>
            )}
        </>
    );
};

export default ViewTaskMap;
