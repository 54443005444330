import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "../../../components/css/Sidebar.css";
import FileUpload from "../../partials/FileUpload";
import SelectInput from "../../partials/SelectInput";

const RmuWithTcAddNetwork = ({
    setRightSidebarVisible,
    selectedMarker,
    onSave,
    onFormSave,
    networkPayload,
}) => {
    const [formData, setFormData] = useState({
        structure_type: selectedMarker?.options?.eleProp?.properties?.point_props.support_type || "",
        earthing_type: selectedMarker?.options?.eleProp?.properties?.point_props.earthing_type || "",
        rmu_type: selectedMarker?.options?.eleProp?.properties?.point_props.rmu_type || "",
        rmu_make_by: selectedMarker?.options?.eleProp?.properties?.point_props.rmu_make_by || "",
        scada_compatible: selectedMarker?.options?.eleProp?.properties?.point_props.scada_compatible || "",
        rmu_operation: selectedMarker?.options?.eleProp?.properties?.point_props.rmu_operation || "",
        has_fencing: selectedMarker?.options?.eleProp?.properties?.point_props.has_fencing || "",
        loc_name: selectedMarker?.options?.eleProp?.properties?.point_props.loc_name || "",
        serial_no: selectedMarker?.options?.eleProp?.properties?.point_props.serial_no || "",
        position: selectedMarker?.options?.eleProp?.properties?.line_props.position || "",
        type: selectedMarker?.options?.eleProp?.properties?.line_props.type || "",
        fileUrl: (selectedMarker?.options?.eleProp?.properties?.point_props.fileUrl || ""),
        imgUrl: (selectedMarker?.options?.eleProp?.properties?.point_props.imgUrl || ""),
    });

    const [showLoadAndStartButtons, setShowLoadAndStartButtons] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isSaveAsCopyDisabled, setIsSaveAsCopyDisabled] = useState(true);

    // Load data from localStorage and check button visibility
    useEffect(() => {
        const savedData = localStorage.getItem("rmuwithtcFormData");
        setShowLoadAndStartButtons(!!savedData);
        if (selectedMarker?.options?.eleProp?.id) {
            const data = networkPayload.find(
                (item) => item.id === selectedMarker?.options?.eleProp?.id
            );
            setFormData({
                structure_type: data?.properties?.point_props.structure_type || "",
                earthing_type: data?.properties?.point_props.earthing_type || "",
                rmu_type: data?.properties?.point_props.rmu_type || "",
                rmu_make_by: data?.properties?.point_props.rmu_make_by || "",
                scada_compatible: data?.properties?.point_props.scada_compatible || "",
                rmu_operation: data?.properties?.point_props.rmu_operation || "",
                has_fencing: data?.properties?.point_props.has_fencing || "",
                loc_name: data?.properties?.point_props.loc_name || "",
                serial_no: data?.properties?.point_props.serial_no || "",
                position: data?.properties?.line_props.position || "",
                type: data?.properties?.line_props.type || "",
                fileUrl: (data?.properties?.point_props.fileUrl || ""),
                imgUrl:  (data?.properties?.point_props.imgUrl || ""),
            });
        }
    }, [selectedMarker]);

    const exemptKeys = ['remarks', 'fileUrl', 'imgUrl'];

    // Update save buttons state based on form data
    useEffect(() => {
        const allFieldsFilled = Object.entries(formData).every(
            ([key, value]) => {
                // Allow 'remarks' to be blank
                if (exemptKeys.includes(key)) return true;
                return value !== '';
            }
        );
        setIsSaveDisabled(!allFieldsFilled);
        setIsSaveAsCopyDisabled(!allFieldsFilled);
    }, [formData]);

    // Clear form data
    const clearForm = () => {
        setFormData({
            structure_type: "",
            earthing_type: "",
            rmu_type: "",
            rmu_make_by: "",
            scada_compatible: "",
            rmu_operation: "",
            has_fencing: "",
            loc_name: "",
            serial_no: "",
            position: "",
            type: "",
            fileUrl: "",
            imgUrl:"",
        });
        const savedData = localStorage.getItem("rmuwithtcFormData");
        setShowLoadAndStartButtons(!!savedData);
    };

    // Handle form changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleFileUpload = (key, fileUrl) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: fileUrl
        }));
    };

    const handleFileRemove = (key) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: ''
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const element = selectedMarker.options.eleProp;

        // Prepare the form data
        const requestBody = {
            id: element.id,
            type: "Feature",
            geometry: element.geometry,
            project_id: element.project_id,
            properties: {
                id: element.id,
                parent_id: element.properties.parent_id,
                point_type: "RMU with TC",
                point_props: {
                    structure_type: formData.structure_type,
                    earthing_type: formData.earthing_type,
                    rmu_type: formData.rmu_type,
                    rmu_make_by: formData.rmu_make_by,
                    scada_compatible: formData.scada_compatible,
                    rmu_operation: formData.rmu_operation,
                    has_fencing: formData.has_fencing,
                    loc_name: formData.loc_name,
                    serial_no: formData.serial_no,
                    fileUrl: formData.fileUrl,
                    imgUrl: formData.imgUrl
                },
                line_props: {
                    position: formData.position,
                    type: formData.type,
                },
                data_type: "ht_location",
            },
        };
        onFormSave();
        onSave(requestBody);
        toast.success("Data Save Successfully!");
        setRightSidebarVisible(false);
        clearForm();
    };

    // Handle save as copy
    const handleSaveAsCopy = (e) => {
        if (!isSaveAsCopyDisabled) {
            localStorage.setItem("rmuwithtcFormData", JSON.stringify(formData));
            // Clear the form after saving as copy
            handleSubmit(e);
        }
    };

    // Handle load last copy
    const handleLoadLastCopy = () => {
        const savedData = localStorage.getItem("rmuwithtcFormData");
        if (savedData) {
            setFormData(JSON.parse(savedData));
        }
    };

    // Handle Clear
    const handleStartNew = () => {
        clearForm();
    };

    return (
        <div>
            {/* Show Load Last Copy and Clear buttons if there is saved data */}
            {showLoadAndStartButtons && (
                <div className="mb-3">
                    <button
                        type="button"
                        className="save-button"
                        style={{
                            padding: "8px 30px",
                            backgroundColor: "#427d9d",
                            borderRadius: "8px",
                            color: "white",
                            fontSize: "12px",
                            marginTop: "20px",
                            marginRight: "10px",
                        }}
                        onClick={handleLoadLastCopy}
                    >
                        Load Last Copy
                    </button>
                    <button
                        type="button"
                        className="save-button"
                        style={{
                            padding: "8px 30px",
                            backgroundColor: "#427d9d",
                            borderRadius: "8px",
                            color: "white",
                            fontSize: "12px",
                            marginTop: "20px",
                        }}
                        onClick={handleStartNew}
                    >
                        Clear
                    </button>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                className="form-label1"
                                htmlFor="structure_type"
                            >
                                Structure Type *
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id="structure_type"
                                value={formData.structure_type}
                                onChange={handleChange}
                            >
                                <option value="">Select structure type</option>
                                <option value="Plinth">Plinth</option>
                                <option value="Double Pole">Double Pole</option>
                                <option value="Four Pole">Four Pole</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                className="form-label1"
                                htmlFor="earthing_type"
                            >
                                Earthing Type *
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id="earthing_type"
                                value={formData.earthing_type}
                                onChange={handleChange}
                            >
                                <option value="">Select earthing type</option>
                                <option value="GI Wire">GI Wire</option>
                                <option value="GI Strip">GI Strip</option>
                                <option value="Not Provided">
                                    Not Provided
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="rmu_type">
                                RMU Type *
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id="rmu_type"
                                value={formData.rmu_type}
                                onChange={handleChange}
                            >
                                <option value="">Select RMU type</option>
                                <option value="2 Way">2 Way</option>
                                <option value="3 Way">3 Way</option>
                                <option value="4 Way">4 Way</option>
                                <option value="5 Way">5 Way</option>
                                <option value="6 Way">6 Way</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                className="form-label1"
                                htmlFor="rmu_make_by"
                            >
                                RMU Make By *
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id="rmu_make_by"
                                value={formData.rmu_make_by}
                                onChange={handleChange}
                            >
                                <option value="">Select RMU make</option>
                                <option value="ABB">ABB</option>
                                <option value="CG Lucy">CG Lucy</option>
                                <option value="C&S electric">
                                    C&S electric
                                </option>
                                <option value="Jyoti">Jyoti</option>
                                <option value="Lucy">Lucy</option>
                                <option value="Schneider">Schneider</option>
                                <option value="Siemens">Siemens</option>
                                <option value="Voltamp">Voltamp</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                className="form-label1"
                                htmlFor="scada_compatible"
                            >
                                Scada Compatible *
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id="scada_compatible"
                                value={formData.scada_compatible}
                                onChange={handleChange}
                            >
                                <option value="">Select option</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                className="form-label1"
                                htmlFor="rmu_operation"
                            >
                                Operation Type *
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id="rmu_operation"
                                value={formData.rmu_operation}
                                onChange={handleChange}
                            >
                                <option value="">Select operation type</option>
                                <option value="Manual">Manual</option>
                                <option value="Motorized Local">
                                    Motorized Local
                                </option>
                                <option value="Motorized + Remote">
                                    Motorized + Remote
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label
                                className="form-label1"
                                htmlFor="has_fencing"
                            >
                                Has Fencing *
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id="has_fencing"
                                value={formData.has_fencing}
                                onChange={handleChange}
                            >
                                <option value="">Select option</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="loc_name">
                                Location Name *
                            </label>
                            <input
                                placeholder="Enter location name"
                                type="text"
                                id="loc_name"
                                className="form-control form-control-sm"
                                value={formData.loc_name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="serial_no">
                                Serial No *
                            </label>
                            <input
                                placeholder="Enter serial number"
                                type="text"
                                id="serial_no"
                                className="form-control form-control-sm"
                                value={formData.serial_no}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="imgUrl">
                                Upload Image:
                            </label>
                            <FileUpload
                                fileName={"imgUrl"}
                                existingFile={formData.imgUrl}
                                className="form-control"
                                onFileUpload={handleFileUpload}
                                onFileRemove={handleFileRemove}
                            />{" "}
                            {/* Use the FileUpload component */}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="fileUrl">
                                Upload Document:
                            </label>
                            <FileUpload
                                fileName={"fileUrl"}
                                existingFile={formData.fileUrl}
                                className="form-control"
                                onFileUpload={handleFileUpload}
                                onFileRemove={handleFileRemove}
                            />{" "}
                            {/* Use the FileUpload component */}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <h6 className="sub-menu-title mb-0">
                                Line Properties
                            </h6>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <SelectInput
                            name="position"
                            label="Line Position *"
                            options={["Overhead", "Underground"]}
                            value={formData.position}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12">
                        <SelectInput
                            name="type"
                            label="Line Type *"
                            options={
                                formData.position === "Underground"
                                    ? [
                                          "3C X 70sqmm",
                                          "3C X 95sqmm",
                                          "3C x 120sqmm",
                                          "3C X 150sqmm",
                                          "3C X 185sqmm",
                                          "3C X 225sqmm",
                                          "3C X 240sqmm",
                                          "3C X 300 sqmm",
                                      ]
                                    : [
                                          "AAAC-Weasel (34 mm2)",
                                          "AAAC-Rabbit (55 mm2)",
                                          "AAAC-DOG (100 mm2)",
                                          "AAAC Rabbit - Coated (55mm2)",
                                          "AB Cable (120 mm2)",
                                          "AB Cable (35 mm2)",
                                          "AB Cable (70 mm2)",
                                          "AB Cable (95 mm2)",
                                          "AB Cable(120 mm2)",
                                          "ACSR-DOG (105 mm2)",
                                          "ACSR-Rabbit (50 mm2)",
                                          "ACSR-Rabbit (55 mm2)",
                                          "ACSR-Wease (30 mm2)",
                                          "ACSR-Weasel (34 mm2)",
                                          "AL 59 Dog (117 mm2)",
                                          "MVCC AL59 ACS Rabbit(52 mm2)",
                                          "3C X 70sqmm",
                                          "3C X 95sqmm",
                                          "3C x 120sqmm",
                                          "3C X 150sqmm",
                                          "3C X 185sqmm",
                                          "3C X 225sqmm",
                                          "3C X 240sqmm",
                                          "3C X 300 sqmm",
                                      ]
                            }
                            value={formData.type}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="text-center">
                    <button
                        type="button"
                        className="save-button"
                        onClick={handleSaveAsCopy}
                        disabled={isSaveAsCopyDisabled}
                    >
                        Save as Copy
                    </button>
                    <button
                        type="submit"
                        className="save-button"
                        onClick={handleSubmit}
                        disabled={isSaveDisabled}
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default RmuWithTcAddNetwork;
