import React, { useState, useRef, useEffect } from "react";
import Autosuggest from "react-autosuggest";

const TopNavbar = ({ organizations, sendDataToParent }) => {
    const [formData, setFormData] = useState({ project_id: "", category: "" });
    const [findData, setFindData] = useState([]);
    const [showCategories, setShowCategories] = useState(false);
    const [fpiData, setFPIData] = useState(null);
    const inputRef = useRef(null);
    const categories = ["Project Name", "Project ID", "Location ID"];

    const handleChange = (e, { newValue }) => {
        setFormData((prevState) => ({
            ...prevState,
            project_id: newValue,
        }));

        // Show or hide categories based on formData.category
        setShowCategories(formData.category === "");
    };

    const handleCategoryClick = (category) => {
        setFormData((prevData) => ({
            ...prevData,
            category,
            project_id: "", // Reset project_id
        }));
        setShowCategories(false);
    };

    const handleFocus = () => {
        if (!formData.category) {
            setShowCategories(true);
        }
    };

    const handleBlur = () => {
        setTimeout(() => setShowCategories(false), 100); // Timeout to allow click events to register
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Here you can do something with the form data, like send it to a server
        try {
            const projectIds = formData.project_id;
            if (projectIds) {
                let location_id = "";
                if(formData.category === 'Location ID'){
                    location_id = formData.project_id;
                }

                const myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");

                const formdataAPI = new FormData();
                formdataAPI.append("project_ids", projectIds);
                formdataAPI.append("category", formData.category);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdataAPI,
                    redirect: "follow",
                };

                const responseLine = await fetch(
                    process.env.REACT_APP_API_URL +
                        "/api/get-project-line-data",
                    requestOptions
                );
                const line = await responseLine.json();

                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        "/api/get-project-point-data",
                    requestOptions
                );
                const data = await response.json();

                if (data.features === null) {
                    window.alert("No Data found.");
                    setFormData({
                        project_id: "",
                    });
                    throw new Error("An error occurred!");
                }

                const fpiResponse = await fetch(
                    process.env.REACT_APP_API_URL + "/api/get-project-fpi-data",
                    requestOptions
                );
                const fpiJsonData = await fpiResponse.json();
                setFPIData(fpiJsonData); // Store point data

                // const hierarchiesResponse = await fetch(
                //     process.env.REACT_APP_API_URL + "/api/get-project-hierarchies",
                //     requestOptions
                // );
                // const hierarchiesJsonData = await hierarchiesResponse.json();

                var array = [];
                data.features.map((value, index) => {
                    if (value.properties.point_type === "HT Pole") {
                        if (!array.includes("Support Pole")) {
                            array.push("Support Pole");
                        }
                    } else {
                        if (!array.includes(value.properties.point_type)) {
                            array.push(value.properties.point_type);
                        }
                    }
                });

                sendDataToParent(data, line, [], fpiJsonData, projectIds, location_id);
            } else {
                window.alert("Please check your input.");
            }
        } catch (error) {}
        // Reset the form after submission
        setFormData({
            project_id: "",
            category: ""
        });
    };

    const handleSuggestionsFetchRequested = ({ value }) => {
        if (value.length < 3) {
            setFindData([]);
            return;
        }

        const filteredSuggestions = getFilteredSuggestions(value);
        setFindData(filteredSuggestions);
    };

    const handleSuggestionsClearRequested = () => {
        setFindData([]);
    };

    const getFilteredSuggestions = (value) => {
        const { category } = formData;
        if (!category) return [];

        const lowerValue = value.toLowerCase();
        const filterFn = (filter) =>
            filter.label.toLowerCase().includes(lowerValue);

        switch (category) {
            case "Company":
                return organizations.companies.filter(filterFn);
            case "Circle":
                return organizations.circle.filter(filterFn);
            case "Division":
                return organizations.divisions.filter(filterFn);
            case "Sub Division":
                return organizations.subDivisions.filter(filterFn);
            default:
                return [];
        }
    };

    const getSuggestionValue = (suggestion) => suggestion.label;

    const renderSuggestion = (suggestion) => (
        <div className="suggestion-item-label">{suggestion.label}</div>
    );

    const handleKeyDown = (e) => {
        if (e.key === "Tab" && findData.length > 0) {
            e.preventDefault();
            const firstSuggestion = findData[0];
            setFormData((prevState) => ({
                ...prevState,
                project_id: firstSuggestion.label,
            }));
            setFindData([]);
        }
    };

    const getPlaceholder = () => {
        switch (formData.category) {
            case "Company":
                return "Search for a company...";
            case "Circle":
                return "Search for a circle...";
            case "Division":
                return "Search for a division...";
            case "Sub Division":
                return "Search for a sub division...";
            default:
                return "Search...";
        }
    };

    const handleShowCategory = () => {
        setShowCategories(true);
    };

    const handleCancel = () => {
        setFormData({ project_id: "", category: "" });
        setFindData([]);
        setShowCategories(false);
        inputRef.current.blur(); // Optionally blur the input to close suggestions
    };

    const isCancelButtonEnabled = formData?.project_id || formData?.category;

    return (
        <nav className="top-navbar">
            <div className="top-navbar-logo">
                <img
                    src="grid_view.jpg"
                    style={{ width: "150px", height: "50px" }}
                    title="IntelliGIS"
                    alt="IntelliGIS Logo"
                />
            </div>
            <form className="main-search" onSubmit={handleSubmit}>
                <div className="form-inline">
                    {formData.category && (
                        <a
                            onClick={handleShowCategory}
                            className="category-button me-1"
                        >
                            {formData.category}
                        </a>
                    )}
                    <Autosuggest
                        suggestions={findData}
                        onSuggestionsFetchRequested={
                            handleSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                            handleSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            ref: inputRef,
                            type: "text",
                            name: "project_id",
                            value: formData.project_id,
                            onChange: handleChange,
                            onFocus: handleFocus,
                            onBlur: handleBlur,
                            onKeyDown: handleKeyDown,
                            placeholder: getPlaceholder(),
                        }}
                        theme={{
                            suggestionsContainer: "suggestions-container",
                            suggestion: "suggestion-item",
                            suggestionHighlighted:
                                "suggestion-item-highlighted",
                        }}
                        className="search-input"
                    />
                    <button type="submit" className="search-btn"></button>
                    {isCancelButtonEnabled && (
                        <button
                            type="button"
                            className={`cancel-btn ${
                                isCancelButtonEnabled ? "enabled" : "disabled"
                            }`}
                            onClick={handleCancel}
                            disabled={!isCancelButtonEnabled} // Disable the button if no data is selected
                        ></button>
                    )}
                </div>
                {showCategories && (
                    <div className="categories-dropdown">
                        {categories.map((category, index) => (
                            <span
                                className=""
                                key={index}
                                onMouseDown={() =>
                                    handleCategoryClick(category)
                                }
                            >
                                {category}
                            </span>
                        ))}
                    </div>
                )}
            </form>
            <div className="top-navbar-links">
            <img
                    src="IntelliGIS_Fornax.png"
                    style={{ width: "150px", height: "50px" }}
                    title="IntelliGIS"
                    alt="IntelliGIS Logo"
                />
            </div>
        </nav>
    );
};

export default TopNavbar;
