import L from 'leaflet';
import * as turf from '@turf/turf';

export const calculateDistance = (point, cursorPosition) => {
    if (cursorPosition) {
        let result = point.distanceTo(cursorPosition);
        return  (result/1000).toFixed(2);
    }

    return 0;
};

export const calculateLineMiddlePoint = (lineCoordinates) => {
    const latlng1 = lineCoordinates[0];
    const latlng2 = lineCoordinates[1];

    return [
        (latlng1[1] + latlng2[1]) / 2,
        (latlng1[0] + latlng2[0]) / 2
    ];
};

export const getMidpoint = (latlng1, latlng2) => {
    return L.latLng(
        (latlng1.lat + latlng2.lat) / 2,
        (latlng1.lng + latlng2.lng) / 2
    );
};

export const createDistanceIcon = (distance) => {
    return L.divIcon({
        className: 'distance-text',
        html: `<div style="padding: 10px 5px;font-weight: 900;background: #fff;width: 150px;">${distance} Km</div>`,
    });
};

/**
 * Extracts unique point types from a given dataset.
 * @param {Array} data - The array of data objects.
 * @return {Array} An array of unique point types.
 */
export function extractUniquePointTypes(data) {
    const uniqueTypes = new Set();  // Using a Set to ensure uniqueness
    data.forEach(item => {
        if (item.properties && item.properties.point_type) {
            uniqueTypes.add(item.properties.point_type);
        }
    });
    return Array.from(uniqueTypes);  // Convert Set to Array
}

export function isPointOnLine(point, lineString) {
    // Create a Turf point and lineString from GeoJSON coordinates
    const turfPoint = turf.point(point.coordinates);
    const turfLine = turf.lineString(lineString.coordinates);
    
    // Check if the point is on the line using Turf.js
    return turf.booleanPointOnLine(turfPoint, turfLine);
}

export const pointsOnLine = (linesGeoJSON, pointsGeoJSON) => {
    let pointsOnTheLine = [];

    // Ensure linesGeoJSON is an array and iterate over each line feature
    if (Array.isArray(linesGeoJSON)) {
        linesGeoJSON.forEach(lineFeature => {
            if (lineFeature.geometry && Array.isArray(lineFeature.geometry.coordinates)) {
                const turfLine = turf.lineString(lineFeature.geometry.coordinates);

                // Ensure pointsGeoJSON is a GeoJSON FeatureCollection
                if (pointsGeoJSON.type === 'FeatureCollection' && Array.isArray(pointsGeoJSON.features)) {
                    pointsGeoJSON.features.forEach(pointFeature => {
                        if (pointFeature.geometry && Array.isArray(pointFeature.geometry.coordinates)) {
                            const point = turf.point(pointFeature.geometry.coordinates);

                            // Check if the point is on the line using Turf.js
                            if (turf.booleanPointOnLine(point, turfLine)) {
                                pointsOnTheLine.push(pointFeature);
                            }
                        }
                    });
                }
            }
        });
    }

    // Return a new GeoJSON FeatureCollection of points that are on the line
    return turf.featureCollection(pointsOnTheLine);
}

export const convertClassNameString = (str) => {
    return str.toLowerCase().replace(/\s+/g, '_');
};