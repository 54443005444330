import React, { useState } from "react";
import { useMap, Marker, Popup  } from "react-leaflet";

const SearchLatLng = () => {
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [zoom, setZoom] = useState(18);
  const [isFormVisible, setIsFormVisible] = useState(false); // State to toggle form visibility
  const [markerPosition, setMarkerPosition] = useState(null); 

  const map = useMap();

  const handleSearch = () => {
    console.log("test submit");
    if (!lat || !lng) {
      alert("Please enter both latitude and longitude");
      return;
    }
    const latNum = parseFloat(lat);
    const lngNum = parseFloat(lng);

    if (isNaN(latNum) || isNaN(lngNum)) {
      alert("Please enter valid numbers for latitude and longitude");
      return;
    }

    // Pan and zoom to the entered lat, lng
    map.setView([latNum, lngNum], zoom);
    setMarkerPosition([latNum, lngNum]);
  };

  return (
    <div style={{ position: "absolute", top: 10, left: 65, zIndex: 1000 }}>
      {/* Button to show/hide the form */}
      <button type="button" onClick={() => setIsFormVisible(!isFormVisible)} style={{boxShadow:"none",border:"none",background:"#164863",padding:"9px 15px",color:"#fff", "border-radius":"30px", fontFamily: '"Poppins", sans-serif',fontSize:"12px",lineHeight: '1', marginLeft:'0px',position:"absolute",top:'10px',left:'6px',"text-wrap-mode":"nowrap"}}>
        {isFormVisible ? "X" : "Search (x, y)"}
      </button>

      {/* Conditional rendering for the form */}
      {isFormVisible && (
        <div style={{ marginTop: 50,display:"flex",alignItems:"center",marginLeft:10 }}>
          <input
            type="text"
            placeholder="Latitude"
            value={lat}
            onChange={(e) => setLat(e.target.value)}
            style={{ marginRight: 5 }}
          />
          <input
            type="text"
            placeholder="Longitude"
            value={lng}
            onChange={(e) => setLng(e.target.value)}
            style={{ marginRight: 5 }}
          />
          <button onClick={handleSearch} style={{boxShadow:"none",border:"none",background:"#164863",padding:"8px 25px",color:"#fff", "border-radius":"30px",fontSize:14}}>Search</button>
        </div>
      )}

        {markerPosition && (
            <Marker position={markerPosition}>
                <Popup>
                    Searched Location: {markerPosition[0]}, {markerPosition[1]}
                </Popup>
            </Marker>
        )}
    </div>
  );
};

export default SearchLatLng;
