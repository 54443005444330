// src/utils/apiService.js
const apiService = async (
    endpoint,
    method = "GET",
    body = null,
    additionalHeaders = {}
) => {
    try {
        // Create default headers
        const myHeaders = new Headers({
            Accept: "application/json",
            ...additionalHeaders, // Merge additional headers if provided
        });

        // If the body is FormData, do not add Content-Type (it is set automatically)
        if (!(body instanceof FormData)) {
            myHeaders.append("Content-Type", "application/json");
        }

        const requestOptions = {
            method,
            headers: myHeaders,
            body,
            redirect: "follow",
        };

        // Make the API call
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/${endpoint}`,
            requestOptions
        );

        // Check for a successful response
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        // Parse and return the JSON response
        return await response.json();
    } catch (error) {
        console.error("API Error:", error.message);
        throw error;
    }
};

export default apiService;
